<div class="wrapper">
  <div class="month-header">
    <div class="griditems">
      <b style="cursor: pointer;" matTooltip="{{this.alertSpecificData?.name}}">{{this.alertSpecificData?.name.length > 12 ? (this.alertSpecificData?.name | slice:0:12) + '...' : this.alertSpecificData?.name }}</b>
      <br>
      {{"title.net/queue" | translate | async }}:
      <b>{{ this.monthlyMetricItems?.netQueueAva }}</b>
    </div>
    <div class="griditems">
      {{"title.pool/Availablility" | translate | async }}:
      <b>{{ this.monthlyMetricItems?.poolAvail }}</b>
    </div>
    <div class="griditems">
      {{"title.pool/utilization" | translate | async }}
      <b>{{this.monthlyMetricItems?.utilization }}</b>
    </div>
    <div class="filter-container griditems ">
    <app-filter-container  [setDatePickerValues]="setDatePickerValues" >
      <app-alert-resolve-filters  [setDatePickerValues]="setDatePickerValues"
        [alertSpecificData]="alertSpecificData"></app-alert-resolve-filters>
    </app-filter-container>
  </div>
    <div class="griditems" >
      <mat-radio-group style="position: absolute;
      right: 23px;
      top: 9px;" [(ngModel)]="getAlertTimeRange" (change)="getSelectedTimeRange()">
        <span class="Alert_hours" style="margin-right: 14px;">
          <mat-radio-button class="example-margin" value="Alerthours">{{"label.alert/hours" | translate | async
            }}</mat-radio-button>
        </span>
        <span>
          <mat-radio-button class="example-margin" value="Allhours">{{ "label.all/hours" | translate | async
            }}</mat-radio-button>
        </span>
      </mat-radio-group>
    </div>
  </div>
  <div class="week-and-day-wrapper">
    <div class="week-data">
      <div class="box-above-week-data" aria-hidden="true"></div>
      <app-alert-resolve-calendar-item *ngFor="let item of weeklyMetricItems" [isWeek]="true" [metricItem]="item" [id]="getCalendarDetails"
        [alertSpecificData]="alertSpecificData"></app-alert-resolve-calendar-item>
    </div>
    <div class="daily-grid">
      <p class="week-day-header">{{ "label.dayOfWeek.sunday" | translate | async }}</p>
      <p class="week-day-header">{{ "label.dayOfWeek.monday" | translate | async }}</p>
      <p class="week-day-header">{{ "label.dayOfWeek.tuesday" | translate | async }}</p>
      <p class="week-day-header">{{ "label.dayOfWeek.wednesday" | translate | async }}</p>
      <p class="week-day-header">{{ "label.dayOfWeek.thursday" | translate | async }}</p>
      <p class="week-day-header">{{ "label.dayOfWeek.friday" | translate | async }}</p>
      <p class="week-day-header">{{ "label.dayOfWeek.saturday" | translate | async }}</p>
      <app-alert-resolve-calendar-item *ngFor="let item of dailyMetricItems" [metricItem]="item" [id]="getCalendarDetails"
        [alertSpecificData]="alertSpecificData"></app-alert-resolve-calendar-item>
    </div>
  </div>
</div>