<div [formGroup]="addPoolConsumptionSearch">
  <div>
    <mat-form-field appearance="outline" class="textSize">
      <mat-label>{{'label.find_pool' | translate | async }}</mat-label>
      <input matInput placeholder="Search Pool" formControlName="search" (keydown)="inInput($event)"
        (click)="openExamsListDialog()">
      <span
        *ngIf="(registerFormControl.search.touched ||registerFormControl.search.dirty) && registerFormControl.search.errors?.required"></span>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="outline" class="textSize">
      <mat-label>{{'label.queues' | translate | async }}</mat-label>
      <input matInput placeholder="Queue" formControlName="Queue">
      <span
        *ngIf="(registerFormControl.Queue.touched ||registerFormControl.Queue.dirty) && registerFormControl.Queue.errors?.required"></span>
    </mat-form-field>
  </div>
  <h4>{{'label.date' | translate | async }}</h4>
  <div class="two-columns">
    <vue-datepicker label="{{ 'label.startdate' | translate | async }}"
      placeholder="{{ 'placeholder.datepicker' | translate | async }}" formControlName="start" [required]="true"
      [errorMatcher]="datePickerErrorMatcher" [minDateIsToday]="false" [minDate]="updatedStartDateValue"
      [maxDate]="addPoolConsumptionSearch.controls.EndDate.value ? addPoolConsumptionSearch.controls.EndDate.value : updatedEndDateValue"
      [errorMessage]="addPoolConsumptionSearch.controls.start.errors | datepickerError | translate | async"
      class="demodar"></vue-datepicker>
    <vue-datepicker label="{{ 'label.enddate' | translate | async }}"
      placeholder="{{ 'placeholder.datepicker' | translate | async }}" formControlName="EndDate" [required]="true"
      [errorMatcher]="datePickerErrorMatcher" [minDateIsToday]="false"
      [minDate]="(startDatedata < todays && patchAlertConfigData) || !addPoolConsumptionSearch.controls.start.value ? updatedStartDateValue : addPoolConsumptionSearch.controls.start.value"
      [maxDate]="updatedEndDateValue"
      [errorMessage]="addPoolConsumptionSearch.controls.EndDate.errors | datepickerError | translate | async"></vue-datepicker>

  </div>
  <div>
    <section class="example-section">
      <mat-checkbox class="example-margin" formControlName="isDateRangeCheck"><b>{{'label.pool_date_range' | translate |
          async
          }}</b></mat-checkbox>
    </section>
  </div>
  <h4>{{'label.time' | translate | async }}</h4>
  <div formArrayName="startTime" *ngFor="let data of FormDatasearchData.controls; let i=index">
    <div [formGroupName]="i">
      <div class="outer-flex-container">
        <div class="inner-flex-container">
          <vue-select class="select_width" label="{{ 'label.placeholder.startTime' | translate | async }}"
            formControlName="startTime" [required]="true" [options]="utcStartTimes"
            [getOptionDisplay]="addUTCToTime.bind(this)" [errorMatcher]="suppressErrorState"></vue-select>
          <vue-select class="select_width" label="{{ 'label.placeholder.endTime' | translate | async }}"
            formControlName="endTime" [options]="utcEndTimes" [getOptionDisplay]="addUTCToTime.bind(this)"
            [required]="true" [errorMatcher]="suppressErrorState"></vue-select>
        </div>
        <span class="crossButton" (click)="removedatafromSearchPool(i)">&cross;</span>
      </div>
      <span class="error" *ngIf=" getValidationcontrols(i).controls['endTime'].errors && (getValidationcontrols(i).controls['endTime'].touched
        && getValidationcontrols(i).controls['endTime'].dirty)"><small>{{'message.warning' | translate |
          async}}</small> </span>
    </div>
  </div>
  <div>
    <section class="example-section">
      <mat-checkbox class="example-margin" formControlName="isTimeRangeCheck"><b>{{'label.pool_time_range' | translate |
          async
          }}</b></mat-checkbox>
    </section>
  </div>
  <div class="addExtracolumns">
    <button mat-button color="primary" *ngIf=" !this.addPoolConsumptionSearch.controls.isTimeRangeCheck.value"
      [disabled]="isformDirty()" (click)="Duplicatethedata()"><span style="font-size: 19px;">+</span>
      &nbsp;{{'label.add_another_time_range' |
      translate | async }}</button>
  </div>
</div>