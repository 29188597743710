<div class="inner-popup">
  <h2 mat-dialog-title style="font-weight: bold;">{{headingDate}}</h2>
  <button mat-button mat-dialog-close><mat-icon>{{ "button.Close" | translate | async }}</mat-icon></button>
</div>
<mat-dialog-content>
  <!-- {{occurrenceForm.value|json}} -->
  <app-error-message *ngIf="status === 'error'" [heading]="displayableServerError?.title"
  [message]="displayableServerError?.message"></app-error-message>
  <form id="occurrence" [formGroup]="occurrenceForm" autocomplete="off" (ngSubmit)="handleSubmit()">
    <div class="items-heading">
      <h5 style="color: #fff; width: 172px;">{{ "label.time" | translate | async }}</h5>
      <h5 class="itemsheading" style="position: relative;
    left: 40px;">{{ "title.net/queue" | translate | async }}</h5>
      <h5 class="itemsheading" style="    position: relative;
    left: 23px;">{{ "title.pool/Availablility" | translate | async }}</h5>
      <h5 class="itemsheading">{{ "title.poolCapacity" | translate | async }}</h5>
    </div>
    <ng-container formArrayName="timeSlots" *ngFor="let occurrence of occurrenceTimeSlots.controls; let i=index">
      <div [formGroupName]="i" class="occurrence-columns" style="height: auto;">
        <div style="width: 168px;">
          <div style="margin-bottom: 7px;">{{editData(i).controls['slot'].value}}(UTC)</div>
          <div style="display: flex;">
            <p *ngIf="editData(i).controls['activeOccHourFlag'].value" style="font-style:italic;">Inactive Occ hour</p>
            <vue-image-tooltip *ngIf="editData(i).controls['activeOccHourFlag'].value"
              imageSrc="/assets/icons/icon-exclamation.svg"
              content=" {{ 'tooltip.message.inativeOccurhour' | translate | async }}"></vue-image-tooltip>
          </div>
          <div style="display: flex; ">
            <p *ngIf="editData(i).controls['activeAlertHourFlag'].value" style="font-style: italic;">Inactive Alert hour
            </p>
            <vue-image-tooltip *ngIf="editData(i).controls['activeAlertHourFlag'].value"
              imageSrc="/assets/icons/icon-exclamation.svg"
              content=" {{ 'tooltip.message.inativealerthour' | translate | async }}"></vue-image-tooltip>
          </div>
        </div>
        <label [for]="'slot' + i" style="position: relative; left: -26px;">
          {{editData(i).controls['netQueueAvailability'].value}}
        </label>
        <label [for]="'slot' + i">
          {{editData(i).controls['poolAvailability'].value}}
        </label>
        <vue-numeric-input [id]="'slot' + i" formControlName="poolCapacity" [maxLength]="5"
          [compact]="true"></vue-numeric-input>
      </div>
    </ng-container>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <vue-flat-button [disabled]="occuranceStatusforapply == true ? occuranceStatusforapply : !occurrenceForm.valid " (click)="handleSubmit()">Apply</vue-flat-button>
</mat-dialog-actions>