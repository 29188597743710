import { formatDate } from '@angular/common';
import { PoolConsumptionStatus } from 'api/types/endpoints/getPoolConsumption';
import { PoolConsumptionPoolDetail } from 'api/types/endpoints/getPoolConsumptionById';

export function isPoolConsumptionEditable<P extends { status: PoolConsumptionStatus, poolDetails: PoolConsumptionPoolDetail}>(poolConsumption: P): boolean {
  const { status, poolDetails } = poolConsumption;

  return !['inactive', 'completed'].includes(status) && ![ 'archived', 'completed' ].includes(poolDetails.poolStatus);
}

export function isPoolConsumptionEnds<P extends { endDate: string }>(pool: P): boolean {
  const { endDate } = pool;

  var currentDate = formatDate(
    new Date().toLocaleString(),
    'yyyy-MM-dd',
    'en-US',
    'UTC'
  );

  const StartDateOnly = endDate;

  if (StartDateOnly < currentDate) {
    return true;
  }

  return false;
}
